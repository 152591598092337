import { App } from './app'
import { getDatabase, off } from "firebase/database";

export const db = getDatabase(App);

// https://firebase.google.com/docs/database/web/start
// https://firebase.google.com/docs/database/web/read-and-write
import { ref, get, onValue, set, push, update, remove} from "firebase/database";

/*
 * Get Real time database value (once)
*/
export const FBgetData = async(path, failVal = false) => {
  try {
    const dbRef = ref(db, path);
    let snapshot = await get(dbRef);
    if (snapshot.exists()) {
      return snapshot.val();
    }
    return failVal
  } catch (err) { 
    console.info(`Error Firebase getDataOnce:`, err.message); 
    return failVal;
  }
}

/*
 * Set monitor on Real time database path
*/
export const FBmonitorData = async(path, callback) => {
  try {
    const dbRef = ref(db, path);
    onValue(dbRef,(snapshot) => {
      const data = snapshot.val();
      callback(data, false)
    });
  } catch (err) { 
    console.info(`Error Firebase getMonitorData:`, err.message); 
    callback(null, err);
  }
}

/*
 * Remove monitor on Real time database path
*/
export const FBRemoveMonitorData = async(path) => {
  try {
    const dbRef = ref(db, path);
    off(dbRef)
  } catch (err) { 
    console.info(`Error Firebase FBRemoveMonitorData:`, err.message); 
    return false;
  }
}

/*
 * Set Real time database path value
*/
export const FBsetData = async (path, val) => {
  try {
    const dbRef = ref(db, path);
    await set(dbRef, val);
    return true;
  } catch (err) { 
    console.info(`Error Firebase setData:`, err.message); 
    return false
  }
}


/*
 * DELETE Real time database path value
*/
export const FBRemoveData = async (path) => {
  try {
    const dbRef = ref(db, path);
    await remove(dbRef);
    return true;
  } catch (err) { 
    console.info(`Error Firebase FBRemoveData:`, err.message); 
    return false;
  }
}


/*
 * Add Real time database path/{Token}/ value
*/
export const FBaddData = async (path, val) => {
  try {
    const dbRef = ref(db, path);
    // Get a key for a new Post.
    const newPostKey = push(dbRef).key;
    console.info(`newPostKey:`, newPostKey);
    
    const updates = {};
    updates[`${path}/${newPostKey}`] = val;
    await update(ref(db), updates);

    return true;
  } catch (err) { 
    console.info(`Error Firebase setData:`, err.message); 
    return false
  }
}


/*
 * Examples
 *
 *    import { FBgetData, FBmonitorData, FBRemoveMonitorData, FBsetData, FBaddData, FBRemoveData }       from '@/plugins/firebase/db';
 * 
      await FBgetData(`/Chime/d3dc9c61-1ed5-4557-a18a-2674be951f49/users/M7PRIwSFxbUxJ6lxVlfJmWuLZxg2`)
      await FBmonitorData(`/Chime/d3dc9c61-1ed5-4557-a18a-2674be951f49/info`, this.FBMonitorCallback1)
      await FBRemoveMonitorData(`/Chime/d3dc9c61-1ed5-4557-a18a-2674be951f49/info`)
      await FBmonitorData(`/Chime/d3dc9c61-1ed5-4557-a18a-2674be951f49/users`, this.FBMonitorCallback2)
      await FBsetData(`/Chime/d3dc9c61-1ed5-4557-a18a-2674be951f49/test`, {top: "ASDFASDF"})
      await FBaddData(`/Chime/d3dc9c61-1ed5-4557-a18a-2674be951f49/test`, {topnew: "newdata??"})
      await FBRemoveData(`/Chime/d3dc9c61-1ed5-4557-a18a-2674be951f49/test/top`)
*/